<template>
  <v-container fill-height fluid class="jet-auth">
    <v-row class="justify-center align-center" style="margin-top: 100px;">
      <v-col cols="12" sm="4">
        <h1 color="primary">
          {{ sysName }}
        </h1>

        <v-form
          @submit.prevent="login"
          v-model="valid"
        >
          <v-text-field
            clearable required
            v-model="username"
            name="u"
            label="Логин"
          ></v-text-field>

          <v-text-field
            clearable required
            v-model="password"
            :append-icon="show ? 'far fa-eye' : 'far fa-eye-slash'"
            :type="show ? 'text' : 'password'"
            name="p"
            label="Пароль"
            @click:append="show = !show"
          ></v-text-field>

          <v-checkbox v-model="save" label="Запомнить?"/>

          <div class="d-flex justify-center">
            <v-btn tile type="submit" color="primary" :loading="loading">
              Войти
            </v-btn>
            
            <v-btn v-if="isesia" tile color="primary" outlined class="ml-8" @click="on_esia">
              Войти через <v-img class="ml-2" src="/gos.svg"/>
            </v-btn>
          </div>
        </v-form>
      </v-col>
    </v-row>

    <p class="footer_copyright" v-html="get('copy')"></p>

    <v-footer app color="gray lighten-3" class="text-right">
      {{ srvName }}

      <v-spacer/>

      {{ copy }}
    </v-footer>
  </v-container>
</template>

<script>
    
import { isEmpty } from '@/utils';

export default {
    name: 'Login',
    data: () => ({
      username: null,
      password: null,
      save: false,
      show: false,
      valid: true,
      loading: false,
      isesia: null
    }),
    mounted() {
      this.$store.getters['profile/isEsia']
        .then((v) => {
          this.isesia = v;
        });
    },
    computed: {
      isAuth() {
            return this.$store.getters['profile/isAuthenticated'];
      },
      sysName() {
        return this.$store.getters['branding/get']('brand.web.system.name');
      },
      srvName() {
        return this.$store.getters['branding/get']('brand.server.name');
      },
      copy() {
        return this.$store.getters['branding/get']('brand.copy');
      }
    },
    methods: {
      login() {
        this.loading = true;

        const params = {
          remember: this.checkbox,
          user: {
            login: this.username,
            password: this.password,
          },
        };

        this.$store.dispatch('profile/login', params)
          .catch(() => {
            this.valid = false;

            setTimeout(() => {
              this.valid = true;
            }, 3000);

            jet.msg({
              text: 'Неверные данные для авторизации. Введите корретный логин/пароль, или обратитесь к администраторам Системы',
              color: 'error',
            });
          })
          .finally(() => {
            this.loading = false;
          });
      },
      get(q){
        switch(q){
          case "copy":
            return isEmpty(this.$store.getters['branding/get']('brand.copy')) 
                      ? this.$store.getters['branding/get']('brand.web.system.name')
                      : this.$store.getters['branding/get']('brand.copy');
        default:
          return '';
        }
      },
      on_esia() {
        const url1 = location.protocol + '//' + location.host;
        const url2 = '/esia?mode=login&mainurl=' + encodeURIComponent(url1 + '/verification')
            + '&redirecturl=' + encodeURIComponent(url1 + '/esia')
            + '&nologouthash=nologout&canfinduser=false&cancreateuserblocked=true';
        location.href = url2;
      }
    },
    watch: {
        isAuth(val) {
            if (val) {
                  this.$router.replace('/');
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.footer_copyright {
  width: 300px;
  margin: 0 auto;
  text-align: center;
  color: #676767;
  font-size: 12px;
}

.jet-auth {
  overflow: hidden;
  max-height: calc(100% - 10rem);

  & h1 {
    margin-bottom: 2rem;
    font-weight: 300;
    line-height: 1.115;
    font-size: 1.5rem;
  }

  & .v-footer {
    font-size: 0.75rem;
  }

  & .v-btn[type="submit"] {
    min-width: 12rem;
  }

  & .v-icon.v-icon {
    font-size: 0.75rem !important;
  }
}
</style>
