<template>
    <v-row class="justify-center align-center" style="height: 100%;">
        <v-col cols="12" sm="6">
            <v-card class="fill-height text-center" width="100%">
                <v-card-title class="text-left" v-if="(!!esia)">
                    Уважаемый(ая), {{esia.title}}
                </v-card-title>
                <v-card-subtitle class="text-left">
                    <span v-if="mode == 'new'">для регистрации учетной записи в системе и назначения полномочий, Вам необходимо предоставить дополнительные данные.</span>
                    <span v-if="mode == 'edit'">Ваша учетная запись находится на проверке.</span>
                    <span v-if="mode == 'verified'">Ваша учетная запись прошла проверку и после настройки будет передана на утверждение.</span>
                    <span v-if="mode == 'configured'">Ваша учетная запись настроена и ожидает утверждения.</span>
                </v-card-subtitle>
                <v-card-text v-if="mode=='new'">
                    <v-form class="jet-form" ref="form">
                        <v-container fluid>
                            <v-layout row wrap>
                                <v-flex offset-xs1>
                                    <jet-input ref="tenantID" label="Организация" type="id" v-model="tenantID"
                                        :disabled="false"
                                        :required="true"
                                        uri="sin2:/v:4d94eecd-bf26-4f12-9bb0-574499a0db4b/?hierarchy=false" 
                                        :max-length="128"/>
                                    <jet-input ref="postName" label="Должность" type="string" v-model="postName"
                                        :required="true" 
                                        :disabled="false"/>
                                    <jet-input ref="others" placeholder="Что будешь в системе делать" type="string" v-model="others"
                                        :rows="10"
                                        :required="true" 
                                        :disabled="false"/>                                
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn outlined tile small color="primary" @click="save" v-if="mode == 'new'">
                        Принять
                    </v-btn>
                    <span class="text-decoration-underline primary--text" style="cursor: pointer;" @click="mode='new'" v-if="mode == 'edit'">
                        Изменить данные
                    </span>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import JetInput from '@/components/editors/JetInput';

export default {
    name: 'Verification',
    components: {
        JetInput
    },
    provide() {
        const self = this;
        return {
            owner: {
                reg(comp){},
                unreg(comp){},
                val(name, val){}
            }
        };
    },
    data() {
        return {
            esia    : null,
            tenantID: null,
            postName: null,
            others  : null,
            adds    : null,
            id      : null,
            exists  : false,
            mode    : null
        }
    },
    mounted() {
        console.log(this.isAuth);
        this.$store.dispatch('profile/checkEsia').then( (data) => {
            this.esia = data;
            this.load();
        });
    },
    methods: {
        async load() {
            const opts = {
                type: 'core-read',
                query: `sin2:/v:0bbcf94d-7e83-4f8a-a571-d20111896d17/?filter=eq(field(".userID"), param("${this.esia.id}", "id"))`
            }
            const adds = await jet.http.post('/rpc?d=jsonRpc', opts);
            if ( !!adds.error ) {
                jet.msg({text:`Ошибка чтения данных. ${adds.error.data}`, color: 'warning'});
                this.mode = 'error';
                return;
            }
            this.exist = ( adds.result.data.length > 0 );
            if ( !!this.exist ) {
                this.adds =  adds.result.data[0];
                this.id = this.adds[adds.result.columnIndexes['sscusersadds.id']];
                this.mode = ( !!this.adds[adds.result.columnIndexes['sscusersadds.configured']] ) ? 'configured'
                                : (( !!this.adds[adds.result.columnIndexes['sscusersadds.verified']] ) ? 'verified' : 'edit');
            } else {
                this.id = jet.utils.uuidv4();
                this.mode = 'new';
            }
        },
        async save() {
            if ( !this.$refs.form.validate() )
                return;
            const opts = {
                type: ( !!this.exist ) ? 'core-update' : 'core-create',
                query: 'sin2:/v:0bbcf94d-7e83-4f8a-a571-d20111896d17',
                params: [
                    {id: 'ID',         type:'id',      value: this.id},
                    {id: 'userID',     type:'id',      value: this.esia.id},
                    {id: 'Title',      type:'string',  value: this.esia.title},
                    {id: 'tenantID',   type:'id',      value: this.tenantID},
                    {id: 'postName',   type:'string',  value: this.postName},
                    {id: 'Others',     type:'string',  value: this.others},
                    {id: 'Verified',   type:'integer', value: 0},
                    {id: 'Configured', type:'integer', value: 0},
                    {id: 'Approved',   type:'integer', value: 0}
                ]
            }
            const result = await jet.http.post('/rpc?d=jsonRpc', opts);
            if ( !!result.error ) {
                jet.msg({text:`Ошибка сохранения данных. ${result.error.data}`, color: 'warning'});
                this.mode = 'error';
                return;
            }
            this.mode = 'edit';
        }
    },
    computed: {
        isAuth() {
            return this.$store.getters['profile/isAuthenticated'];
        }
    },
    watch: {
        isAuth(val) {
            if (val) {
                  this.$router.replace('/');
            }
        }
    }
}
</script>